import DashboardLayout from '@/components/layouts/DashboardLayout';
import Header404 from '../components/Header404';
import { SITE_DOMAIN } from '../utils/constants';

const FourOhFour = () => {
  const pageMeta = {
    title: 'Oops! Sorry there was a problem. 404 | FontVisual',
    description:
      'Sorry! It seems that you have navigated to a page which does not exist.',
    url: `${SITE_DOMAIN}/404`
  };

  return (
    <DashboardLayout pageMeta={pageMeta}>
      <section className="layout">
        <Header404 />
      </section>
    </DashboardLayout>
  );
};

export default FourOhFour;
