import NextLink from 'next/link';
import { Heading, Text, Box } from '@chakra-ui/react';

const Header404 = () => {
  return (
    <Box
      d="flex"
      flexFlow="column"
      alignItems="center"
      justifyContent="center"
      h="md"
    >
      <Heading
        as="h1"
        size="4xl"
        fontFamily="Montserrat"
        mb={4}
        color="primary.800"
      >
        404
      </Heading>
      <Text>
        Visit the{' '}
        <NextLink color="teal.500" href="/" passHref>
          homepage
        </NextLink>{' '}
        or use the navigation links above to continue.
      </Text>
    </Box>
  );
};

export default Header404;
